<template>
  <div class="vz-radiogroup" :class="radiogroupClass">
    <vz-tooltip v-for="(item, index) of radioList" :key="index" :flex="flex" >
      <vz-radiogroup-item
        :item="item"
        :big="big"
        :large="large"
        :disabled="disabled || item.disabled"
        :with-tooltips="withTooltips"
        :with-icon="withIcon"
        :class="isActiveItem(item)"
        @click="onItemClick(item)"
      />
      <template v-if="(disabled || item.disabled) && item.disabledText" #balloon>
        <span v-html="item.disabledText"/>
      </template>
    </vz-tooltip>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import VzRadiogroupItem from '~/components/ui-kit/vz-radiogroup-item.vue';
import { VzRadioGroupItem } from '@/types/types'
import VzTooltip from '~/components/ui-kit/vz-tooltip.vue';
import {UIKitVariants} from '~/types/variants';

const emit = defineEmits([
    'update:modelValue',
    'change'
])
const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean],
    default: ''
  },
  variant: {
    type: String as PropType<UIKitVariants>,
    default: ''
  },
  inline: {
    default: false,
    type: Boolean
  },
  disabled: {
    default: false,
    type: Boolean
  },
  big: {
    default: false,
    type: Boolean
  },
  large: {
    default: false,
    type: Boolean
  },
  withTooltips: {
    default: false,
    type: Boolean
  },
  withIcon: {
    default: false,
    type: Boolean
  },
  flex: {
    type: Boolean,
    default: false
  },
  items: {
    default: false,
    type: Array as PropType<VzRadioGroupItem[]>
  },
})

const radioList = computed((): VzRadioGroupItem[] => {
  return props.items
})

const radiogroupClass = computed((): {[x:string]: boolean | string} => {
  return {
    disabled: props.disabled,
    inline: props.inline,
    big: props.big,
    [props.variant]: props.variant
  }
})

const isActiveItem = (item: VzRadioGroupItem): {[x: string]: boolean} => {
  return {
    active: props.modelValue === item.value
  }
}

const onItemClick = (item: VzRadioGroupItem): void => {
  if (!props.disabled) {
    emit('update:modelValue', item.value)
    emit('change', item)
  }
}

watch(() => props.items, () => {
  if (props.items?.length > 1) {
    const other = props.items.find(item => !item.disabled)
    if (other && props.items.find(item => item.disabled && props.modelValue === item.value)) {
      onItemClick(other)
    }
  }
})
</script>
