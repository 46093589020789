<template>
  <div v-if="item" class="vz-radiogroup-item" :class="{big, large, disabled}" @click="onClick">
    <span class="vz-radiogroup-item-radio"/>
    <span class="vz-radiogroup-item-title" v-html="item.title"/>
    <vz-icon
      v-if="withIcon"
      no-masked-icon
      size="20"
      class="ml-5"
      :url="item.url"
    />
    <vz-tooltip-info
      v-if="hasTooltip"
      class="ml-10"
    >
      <template #baloon>
        <div class="flex flex-align-items-start">
          <img
            v-if="item.tooltipImg"
            :src="item.tooltipImg"
            class="mr-10"
            alt=""
          >
          <span v-html="item.tooltip"/>
        </div>
        <a v-if="item.url" :href="item.url" target="_blank" class="flex flex-end text-decoration-underline mt-5">Подробнее</a>
      </template>
    </vz-tooltip-info>
  </div>
</template>

<script setup lang="ts">
import {VzRadioGroupItem} from '~/types/types'
import {PropType} from 'vue'
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import VzTooltipInfo from '~/components/ui-kit/vz-tooltip-info.vue';

const emit = defineEmits(['click'])
const props = defineProps({
    item: {
        default: null,
        type: Object as PropType<VzRadioGroupItem>
    },
    big: {
        default: false,
        type: Boolean
    },
    large: {
        default: false,
        type: Boolean
    },
    withTooltips: {
        default: false,
        type: Boolean
    },
    withIcon: {
        default: false,
        type: Boolean
    },
    disabled: {
        default: false,
        type: Boolean
    },
})

const hasTooltip = computed((): boolean => {
    return Boolean(props.withTooltips && (props.item.tooltipImg || props.item.tooltip || props.item.url))
})

const onClick = (): void => {
    if (!props.disabled) {
        emit('click', props.item)
    }
}
</script>
